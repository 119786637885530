import { classNames } from '@helpers/scls'
import styles from './Page.module.scss'
import { Button, Text } from '@mantine/core'
import { Flex, FlexProps } from '@components/flex'
import { Markdown, MarkdownVariant, normalizeMarkdown } from '@components/markdown'
import { formatDateWithOrdinal, isPastDate } from '@nextweb/common'

export interface PageDemoProps extends FlexProps {
  /**
   * Title of the feature.
   */
  title: string

  /**
   * Description of the feature. This should be in Markdown format.
   */
  description: string

  /**
   * Suffix shown after the main feature title
   *
   * @default 'in Nextstage'
   */
  titleSuffix?: string | null

  /**
   * Image or slideshow of the feature.
   */
  preview?: React.ReactNode

  /**
   * Date when the page becomes available to general audience.
   */
  availabilityDate?: string | Date

  /**
   * Label to display when the feature will be available.
   */
  availabilityDateLabel?: string

  /**
   * Label to display when the feature is currently available.
   *
   * @default 'Available Now!'
   */
  availableNowLabel?: string | null

  /**
   * Link that brings the user to the URL to schedule a demo.
   */
  demoRegistrationUrl?: string

  /**
   * Label for the demo registration link.
   * @default 'Register for a Demo'
   */
  demoRegistrationLabel?: string
}

function defaultavailabilityDateLabel(
  availabilityDate: string | Date | null
): string | null {
  if (!availabilityDate) return null
  return `Available ${formatDateWithOrdinal(availabilityDate)}`
}

function createAvailabilityLabel(params: {
  availabilityDate: string | Date | null
  availabilityDateLabel: string | null
  availableNowLabel: string | null
}): string | null {
  const { availabilityDate, availabilityDateLabel, availableNowLabel } = params

  if (!availabilityDate) return availabilityDateLabel

  if (isPastDate(availabilityDate)) return availableNowLabel

  return availabilityDateLabel
}

const PageDemo = (props: PageDemoProps) => {
  const {
    className: propsClassName,
    title,
    description,
    demoRegistrationUrl,
    demoRegistrationLabel = 'Register for a Demo',
    titleSuffix = 'in NextStage',
    availabilityDate = null,
    availabilityDateLabel = defaultavailabilityDateLabel(availabilityDate),
    availableNowLabel = 'Available Now!',
    preview,
    children,
    ...forwarded
  } = props

  const className = classNames(styles.demo, propsClassName)

  const availabilityLabel = createAvailabilityLabel({
    availabilityDate,
    availableNowLabel,
    availabilityDateLabel,
  })

  return (
    <Flex
      direction="row"
      fullHeight
      p="lg"
      align="center"
      {...forwarded}
      className={className}
    >
      <Flex direction="column" p="lg" data-testid="page-demo--overview">
        <Flex direction="row" data-testid="page-demo--header">
          <Text size="xl" color="blue" weight="bold">
            {title}
          </Text>
          {titleSuffix && (
            <Text size="xl" sx={{ marginLeft: '0.25em', opacity: 0.67 }}>
              {titleSuffix}
            </Text>
          )}
        </Flex>

        {availabilityLabel && (
          <Flex direction="row" data-testid="page-demo--availability">
            <Text size="lg" sx={{ opacity: 0.67 }}>
              {availabilityLabel}
            </Text>
          </Flex>
        )}

        <Flex direction="row" data-testid="page-demo--description" py="md">
          <Markdown
            data={normalizeMarkdown(description)}
            variant={MarkdownVariant.FEATURE_DESCRIPTION}
            sx={{ opacity: 0.67 }}
          />
        </Flex>

        {children && (
          <Flex direction="row" data-testid="page-demo--children">
            {children}
          </Flex>
        )}

        {demoRegistrationUrl && (
          <Flex direction="row" data-testid="page-demo--registration">
            <Button
              size="md"
              color="blue"
              component="a"
              href={demoRegistrationUrl}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ fontWeight: 'normal' }}
            >
              {demoRegistrationLabel}
            </Button>
          </Flex>
        )}
      </Flex>
      <Flex p="lg" data-testid="page-demo--preview">
        {preview}
      </Flex>
    </Flex>
  )
}

export default PageDemo
